.company {
    padding-top: 25px;
    width: 100%;
}

.company-h1 {
    padding-bottom: 20px;
}

.company h1 {
    color: white;
    padding: 10px 10px 10px 10px;
    font-size: 24px;
    background:#2956A4;
    margin: 0 auto 30px;
    border-radius: 10px;
}

.company p {
    font-size: 18px;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
}