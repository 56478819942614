.services {
    padding-top: 25px;
}

.services h1 {
    color: white;
    padding: 10px 10px 10px 10px;
    font-size: 24px;
    background:#2956A4;
    margin: 0 auto 30px;
    border-radius: 10px;
}


p {
    text-align: center;
}

/* ul.products-services {
    text-align: start;
    padding-left: 100px;
} */

/* ul.products-services li {
    list-style-type: circle;
    list-style-position: outside;
} */

/* ul.maintainance-services {
    text-align: start;
    padding-left: 15vh;
    padding-top: 5vh;
} */

/* ul.maintainance-services li {
    list-style-type: circle;
    list-style-position: outside;
} */