/* html, body {margin: 0; overflow: hidden} */

.App {
  text-align: center;
  width: 100%;
  /* height: 100%; */
}

li {
  list-style-type: none;
}

.mr-sm-6 {
  display: flex;
}

.mr-sm-6 li {
  padding-right: 20px;
}

.mr-sm-6 li a {
  font-size: 32px;
}

.row.logo {
  background-color: #e0e0e0;
  background-image: url("../src/img/asfalt-dark.png");
}

.col-sm-3 img {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

/* nav.effect {
  height: 100%;
} */

/* .justify-content-center {
  padding-top: 10px;
  padding-bottom: 10px;
} */

/* nav ul.tabs {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* nav ul.tabs li {
  padding-top: 10px;
  padding-bottom: 10px;
} */

/* nav ul.tabs li a {
  padding-right: 10px;
  text-decoration: none;
  font-size: 22px;
} */

.link {
  transition: 0.2s ease;
  background-image: transparent;
  color: #2956A4;
  text-decoration: none;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  margin: 0 10px;
}

.link:hover {
  border-top: 4px solid #4066A8;
  border-bottom: 4px solid #4066A8;
  color: wheat;
  padding: 1% 0; 
}

.navbar-inverse .navbar-nav > li > a:hover {
  background-color: gray;
}

.row.noise {
  width: 100%;
  padding-bottom: 6px;
  background: #2956A4;
}

div.end-1 {
  background: #2956A4;
  padding-bottom: 4px;
}

div.end {
  background: #2956A4;
  padding-bottom: 4px;
}

.image-carrousel {
  width: 100%;
  display: block;
}

/* .products-container {
  background: #F8F9FA;
  padding-top: 30px;
  padding-bottom: 30px;
} */

/* .row.products {
  padding-bottom: 20px;
} */

/* .row.products h1 {
  text-align: center;
  color: white;
  padding: 10px 10px 10px 10px;
  font-size: 24px;
  margin-bottom: 30px;
  background:#2956A4;
  margin: 0 auto;
  border-radius: 10px;
} */

/* .justify-content-md-center {
  padding-bottom: 20px;
} */

/* .square {
  transition: .5s ease;
  backface-visibility: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
  -webkit-box-shadow: 0px 0px 16px 1px rgba(41,86,164,0.83);
-moz-box-shadow: 0px 0px 16px 1px rgba(41,86,164,0.83);
box-shadow: 0px 0px 16px 1px rgba(41,86,164,0.83);
} */

/* .square:hover {
  margin-top: -10px;
  margin-bottom: -1px;
  background:#618ae2;
  transition: background-color .2s ease-in-out;
  z-index: 1;
  zoom: 1.1;
} */

.leaflet-container {
  height: 300px;
  width: 100%;
}

.container {
  display: flex;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.data h5 {
  padding-top: 10px;
}

.mobile-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
};

.map {
  margin-top: 2.5%;
  width: 100%;
  padding-left: 50px;
}

.map-desk {
  margin-top: 2.5;
  width: 75%;
}

.message {
  padding-top: 20px;
}

.general {
  padding-right: 100px;
}

.login {
  margin-top: 10%;
  margin-left: 40%;
  margin-right: 40%;
}

.featureText {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 22px;
  text-align: left;
}

.featureTextRight {
  padding-top: 5%;
  padding-right: 5%;
  font-size: 14px;
  text-align: left;
}

.featureTextLeft {
  padding-top: 5%;
  padding-left: 5%;
  font-size: 14px;
  text-align: left;
}